import React from "react";
import "./Progress.scss";
import {techStack} from "../../portfolio";

export default function StackProgress() {
  return (
    <div className="skills-container">
      <div className="skills-bar">
        <h2 className="skills-heading">Le dev que j'aime:</h2>
        {techStack.experience.map((exp, i) => {
          const progressStyle = {
            width: exp.progressPercentage
          };
          return (
            <div key={i} className="skill">
              <p>{exp.Stack}</p>
              <div className="meter">
                <span style={progressStyle}></span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
