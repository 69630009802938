import React, {createRef} from "react";
import "./EducationCard.scss";

export default function EducationCard({school}) {
  const imgRef = createRef();

  const GetDescBullets = ({descBullets}) => {
    return descBullets
      ? descBullets.map((item, i) => (
          <li key={i} className="subTitle">
            {item}
          </li>
        ))
      : null;
  };

  return (
    <div>
      <div className="education-card">
        <div className="education-card-left">
          <img
            crossOrigin={"anonymous"}
            ref={imgRef}
            className="education-roundedimg lazy"
            data-src={school.logo}
            alt={school.schoolName}
          />
        </div>
        <div className="education-card-right">
          <p className="education-text-school">{school.schoolName}</p>
          <div className="education-text-details">
            <p className={"education-text-subHeader"}>{school.subHeader}</p>
            <p className="education-text-desc">{school.desc}</p>
            <p className={`education-text-duration`}>{school.duration}</p>
            <div className="education-text-bullets">
              <ul>
                <GetDescBullets descBullets={school.descBullets} />
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="education-card-border"></div>
    </div>
  );
}
