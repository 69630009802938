import React, {useEffect, useRef, useState} from "react";
import "./Header.scss";
import {greeting} from "../../portfolio";
import {scrollTo} from "../../utils";

function Header() {
  const [visible, setVisible] = useState(true);
  const lastScrollPosition = useRef(0);

  useEffect(() => {
    let ticking = false;

    const handleScroll = () => {
      const currentScroll = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          setVisible(currentScroll < lastScrollPosition.current || currentScroll < 10);
          lastScrollPosition.current = currentScroll;
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="header-container"
         style={{
           top: visible ? "0" : "-200px"
         }}>
      <header className="header">
        <a href="/" className="logo">
          <span>&lt;</span>
          <h1 className="logo-name">{greeting.username}</h1>
          <span>/&gt;</span>
        </a>
        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label className="menu-icon" htmlFor="menu-btn">
          <span className="navicon"></span>
        </label>
        <ul className="menu">
          <li>
            <p
              onClick={() => {
                document.getElementById("menu-btn").checked = false;
                scrollTo("skills");
              }}
            >
              Compétences
            </p>
          </li>
          <li>
            <p
              onClick={() => {
                document.getElementById("menu-btn").checked = false;
                scrollTo("experience");
              }}
            >
              Expériences Professionnelles
            </p>
          </li>
          <li>
            <p
              onClick={() => {
                document.getElementById("menu-btn").checked = false;
                scrollTo("resume");
              }}
            >
              CV
            </p>
          </li>
          <li>
            <p
              onClick={() => {
                document.getElementById("menu-btn").checked = false;
                scrollTo("contact", false);
              }}
            >
              Contacter
            </p>
          </li>
        </ul>
      </header>
    </div>
  );
}

export default Header;
