import React from "react";
import "./SoftwareSkill.scss";
import {skillsSection} from "../../portfolio";

export default function SoftwareSkill() {
  return (
    <div className="software-skills-main-div">
      <ul className="dev-icons">
        {skillsSection.softwareSkills.map((skills, i) => {
          return (
            <li key={i} className="software-skill-inline">
              <i className={skills.fontClassname}></i>
              <p>{skills.skillName}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
