/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  globalColor.scss file

const greeting = {
  username: "Christopher Maugis",
  title: "Hey, moi c'est Christopher",
  subTitle:
    "Développeur web front-end passionné par la webperf. J'ai une préférence pour React et JavaScript (ES6/ES2020) ainsi que TypeScript dernièrement, avec Webpack (combiné avec Encore) pour bundler mes fichiers et Yarn pour gérer mes packages nodes. Pour le back, je manie PHP avec le framework Symfony depuis la version 3."
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/Dev0ph3r",
  linkedin: "https://www.linkedin.com/in/christophermaugis/",
  gmail: "hello@maug.is"
};

// Skills Section

const skillsSection = {
  title: "Ce que je maîtrise",
  subTitle:
    "Orienté principalement front-end, je manipule cependant toutes les stacks",
  skills: [
    "⚡ Développement front-end en JavaScript (vanilla/ES6), avec SASS",
    "⚡ Utilisation du framework React (avec JavaScript ou Typescript)",
    "⚡ Versioning avec Git"
  ],

  softwareSkills: [
    {
      skillName: "React",
      fontClassname: "iconcma-react"
    },
    {
      skillName: "JavaScript",
      fontClassname: "iconcma-javascript"
    },
    {
      skillName: "Sass",
      fontClassname: "iconcma-sass"
    },
    {
      skillName: "Symfony (+Twig)",
      fontClassname: "iconcma-symfony"
    },
    {
      skillName: "HTML5",
      fontClassname: "iconcma-html5"
    },
    {
      skillName: "CSS3",
      fontClassname: "iconcma-css3"
    },
    {
      skillName: "npm",
      fontClassname: "iconcma-npm"
    },
    {
      skillName: "Yarn",
      fontClassname: "iconcma-yarn"
    },
    {
      skillName: "SQL",
      fontClassname: "iconcma-database"
    },
    {
      skillName: "AWS (lambda)",
      fontClassname: "iconcma-amazonaws"
    },
    {
      skillName: "Docker",
      fontClassname: "iconcma-docker"
    },
    {
      skillName: "Git",
      fontClassname: "iconcma-git"
    },
    {
      skillName: "Jira",
      fontClassname: "iconcma-jira"
    },
    {
      skillName: "Confluence",
      fontClassname: "iconcma-confluence"
    }
  ]
};

// Education Section

const educationInfo = {
  schools: [
    {
      schoolName: "ECE Tech, Paris",
      logo: require("./assets/images/eceLogo.webp"),
      subHeader: "Bachelor Informatique",
      duration: "Septembre 2014 - Septembre 2015",
      desc: "Développement génie logiciel web et mobilité (certifiée RNCP niveau II)",
      descBullets: [
        "En alternance dans l'entreprise 3 octets",
        "Mention assez bien"
      ]
    },
    {
      schoolName: "Lycée Paul-Louis Courier, Tours",
      logo: require("./assets/images/lyceePLCLogo.webp"),
      subHeader: "BTS Services informatique aux Organisations",
      duration: "Septembre 2012 - Juillet 2014",
      desc: "Spécialité Solutions Logicielles et Applications Métiers",
      descBullets: ["Note de 20/20 à l'étude de cas (écrit de spécialité)"]
    },
    {
      schoolName: "Lycée Fulbert, Chartres",
      logo: require("./assets/images/fulbertLogo.webp"),
      subHeader: "Bac Sciences et Technologies de la Gestion",
      duration: "Septembre 2010 - Juillet 2012",
      desc: "Option Gestion des Systèmes d'Information",
      descBullets: ["Mention assez bien"]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  experience: [
    {
      Stack: "JavaScript (ES6/ VanillaJS)", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "ReactJS",
      progressPercentage: "70%"
    },
    {
      Stack: "PHP",
      progressPercentage: "55%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  experience: [
    {
      role: "Développeur web confirmé",
      company: "Habitat",
      colorBanner: "rgb(26, 26, 26)",
      companylogo: require("./assets/images/habitatLogo.webp"),
      date: "Mars 2020 - Février 2024",
      desc: "Habitat est une chaîne de magasins spécialisée dans la vente de meubles et d'accessoires de décoration contemporains.",
      descBullets: [
        "Développement des sites internet Habitat et Habitat-Design.",
        "Projet de migration du thème : réécriture de tous les fichiers de style de LESS en SASS, visant à moderniser l'esthétique et l'efficacité du design.",
        "Intégration méticuleuse des maquettes fournies par le prestataire pour assurer une cohérence visuelle et une expérience utilisateur optimale.",
        "Amélioration significative de la web-performance grâce à des analyses approfondies avec Speedcurve et Pagespeed Insight, permettant d'optimiser la vitesse de chargement et la réactivité du site.",
        "Projet en autonomie avec une carte blanche pour la création d'un outil interne novateur, destiné à simplifier et améliorer les processus quotidiens du service marketing.",
        "Mise à jour proactive du framework utilisé, passant de Symfony 3 à 4 puis à Symfony 6, pour bénéficier des dernières fonctionnalités et garantir une base solide et sécurisée.",
        "Création de 3 projets innovants sous React avec Create-React-App, dont 2 connectés à un Symfony en utilisant Api-Platform, pour offrir des solutions interactives et dynamiques.",
        "Mise en place de règles rigoureuses pour la nomenclature simplifiée des commits au sein de l'équipe, accompagnée d'un changelog automatique, favorisant une gestion claire des versions et des évolutions du code.",
        "Encadrement et gestion efficace d'une alternante pendant 1 an, démontrant des compétences en leadership et en mentorat, avec des retours positifs de la part de mon manager.",
        "Projets de refonte approfondie des assets JavaScript avec un passage à ES6 avec Webpack-Encore, ainsi que la suppression totale de jQuery jugé obsolète.",
        "Projet en autonomie d'utilisation astucieuse des images en format WebP. À partir des images PNG, création à la volée d'une image WebP si le navigateur est compatible, en étant connecté à 3 lambdas sous AWS avec du code JavaScript optimisé en termes de rapidité et de consommation d'énergie."
      ]
    },
    {
      role: "Développeur web",
      company: "Rue du Commerce",
      colorBanner: "rgb(185, 193, 201)",
      companylogo: require("./assets/images/rdcLogo.webp"),
      date: "Juillet 2017 - Février 2020",
      desc: "Rue du Commerce est une entreprise française de grande distribution, acteur du secteur du commerce en ligne en France.",
      descBullets: [
        "Je me suis occupé des parties frontales du site (fiche produit, recherche ainsi que le header/footer général) en utilisant Symfony3/4 et PHP7.",
        "J'ai eu comme grand projet la réunification de deux grandes API en une seule. Ce projet était nécessaire car les deux APIS étaient dans un legacy médiocre. Grâce à cette réunification, j'ai pu apprendre et mettre en pratique de nouvelles technologies comme webpack-encore avec ES6. Cette API est utilisée par tous les micro-services du site car elle délivre le header et le footer correspondant à chaque page du site.",
        "La principale recherche lors de la migration vers webpack-encore en utilisant les normes ES6, était la webperf. En effet, la migration des scripts JS était une des étapes qui a rendu Rue du Commerce premier site e-commerce dans le classement du Journal du Net et cela pendant plus d'une année consécutive.",
        "Lors d'un hackathon interne, j'ai pu accompagner les élèves qui étaient venus avec pour sujet de \"Ré-inventer le e-commerce\". Grâce à mes connaissances en front et avec mon œil vigilant sur la web-performance, j'ai sû les guider vers des outils moins gourmands.",
        "Un autre projet innovant fut la création d'un site entièrement contruit en React pour le front ainsi que le Framework API Platform pour la partie back pour l'API. C'est grâce à ce projet que je maîtrise la bibliothèque JavaScript de Facebook. Ce site a été créé from scratch en utilisant Create React App.",
        "De Juillet 2017 à Août 2018 j'étais en prestation au sein de Rue du Commerce via l'entreprise de services du numérique HR Team"
      ]
    },
    {
      role: "Développeur web junior",
      company: "Apivia Courtage",
      colorBanner: "rgb(214, 215, 57)",
      companylogo: require("./assets/images/apiviaLogo.webp"),
      date: "Février 2016 - Mai 2017",
      desc: "Apivia courtage est le premier grossiste mutualiste. Durant plus d'un an en leur compagnie, je me suis perfectionné dans le domaine du développement PHP.",
      descBullets: [
        "Nous avions, pour mission, la gestion des sites intranet et extranet de la société. L'extranet étant utilisé pour les connexions des courtiers (différents tarificateurs et outils pour les aider durant leurs adhésions de leurs clients) et l'intranet, utilisé en interne pour les outils que nous développions à leur demande.",
        "Plusieurs projets en binôme ou trinôme nous ont été confiés, pour les réaliser nous avons utilisé doctrine et un code PHP un petit peu plus orienté objet et ce malgré la non-utilisation d'un framework (les sites étant historiques, il n'est pas possible d'y intégrer un framework sans devoir tout refaire).",
        "Un projet à faire seul m'a été confié, la création d'une GED (gestion électronique de documents) pour le pôle courrier d'Apivia Courtage. Ce projet a été réalisé entre le pôle courrier (recueil de besoins), le pôle développement (moi-même) ainsi que le pôle métier dans lequel les scans devaient être envoyés. Ce projet utilisait les technos suivantes :",
        "PHP 5 en procédural.",
        "Git pour le versionning ainsi que le déploiement via les hooks.",
        "Bootstrap 3",
        "jQuery pour faire des animations fancy.",
        "Une machine de test était à notre disposition sur le réseau interne, nous l'utilisions pour pouvoir apprendre et créer nos propres scripts d'automatisation Git."
      ]
    },
    {
      role: "Développeur / Intégrateur (Alternance)",
      company: "3 octets",
      colorBanner: "rgb(220, 20, 59)",
      companylogo: require("./assets/images/3octetsLogo.webp"),
      date: "Septembre 2014 - Août 2015",
      desc: "Agence digitale accompagnant dans le design, la création et le développement de sites internet et de d'applications web.",
      descBullets: [
        "Pour mon diplôme de bachelor informatique à l'ECE Tech, j'étais en alternance dans l'agence digitale de 3octets. Nous avions en projet le design, la création et le développement de sites internet (tous en full-responsive).",
        "Durant cette année chez eux, j'ai eu pour projet solo la création d'un site pour un client en utilisant le CMS Joomla (ce CMS a été choisi grâce à des plugins demandés), avec l'intégration depuis un fichier PSD venant du graphiste, puis l'installation de Joomla.",
        "D'autres projets en duo sur du Wordpress ou encore des créations de sites vitrines from scratch avec bootstrap m'ont aussi été confiés.",
        "Un projet Symfony2 nous a été confié (vu qu'à la même période j'apprenais Symfony durant mes heures de cours), le projet a duré six mois avec toute une intégration du site, mais aussi une partie back-office pour toute l'administration."
      ]
    }
  ]
};
// Resume Section
const resumeSection = {
  title: "Resume",
  subtitle: "Feel free to download my resume"
};

const contactInfo = {
  title: "Me contacter ☎️",
  email_address: "hello@maug.is"
};

export {
  greeting,
  socialMediaLinks,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  contactInfo,
  resumeSection
};
