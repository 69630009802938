import React from "react";
import "./Greeting.scss";
import "./Button.scss";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import {greeting} from "../../portfolio";
import {scrollTo} from "../../utils";
import profilWebp from "../../assets/images/profil-nobg.webp";
import profilPng from "../../assets/images/profil-nobg.png";

export default function Greeting() {
  return (
    <div className="greet-main" id="greeting">
      <div className="greeting-main">
        <div className="greeting-text-div">
          <div>
            <h2 className={"greeting-text"}>
              {" "}
              {greeting.title} <span className="wave-emoji">👋</span>
            </h2>
            <p className={"greeting-text-p"}>{greeting.subTitle}</p>
            <div id="resume" className="empty-div"></div>
            <SocialMedia />
            <div className="button-greeting-div">
              <p
                className={"main-button"}
                onClick={() => scrollTo("experience")}
              >
                Expériences Pro
              </p>
              <a
                className="main-button"
                href={require("./cv_2024_internet.pdf")}
                rel="noreferrer"
                target={"_blank"}
                aria-label="Télécharger mon CV"
              >
                Mon CV
              </a>
            </div>
          </div>
        </div>
        <div className="greeting-image-div">
          <picture>
            <source srcSet={profilWebp} type="image/webp" />
            <img className="picture" src={profilPng} alt="Christopher Maugis" />
          </picture>
        </div>
      </div>
    </div>
  );
}
