import React from "react";
import "./ExperienceCard.scss";
import {useCollapse} from "react-collapsed";

export default function ExperienceCard({cardInfo}) {
  const {getCollapseProps, getToggleProps, isExpanded} = useCollapse();

  const GetDescBullets = ({descBullets}) => {
    return descBullets
      ? descBullets.map((item, i) => <p key={i}>{item}</p>)
      : null;
  };
  return (
    <div className={"experience-card"}>
      <div
        style={{background: cardInfo.colorBanner}}
        className="experience-banner"
      >
        <div className="experience-blurred_div"></div>
        <div className="experience-div-company">
          <p className="experience-text-company">{cardInfo.company}</p>
        </div>
        <img
          crossOrigin={"anonymous"}
          className="experience-roundedimg lazy"
          data-src={cardInfo.companylogo}
          alt={cardInfo.company}
        />
      </div>
      <div {...getToggleProps()} className="experience-text-details">
        <p className={"experience-text-role"}>{cardInfo.role}</p>
        <p className={"experience-text-date"}>{cardInfo.date}</p>
        <p className={"experience-text-desc"}>{cardInfo.desc}</p>
        <div className={"experience-text-bullet"} {...getCollapseProps()}>
          <GetDescBullets descBullets={cardInfo.descBullets} />
        </div>
        <p className={"main-button"}>
          {isExpanded ? "Réduire" : "Voir plus..."}
        </p>
      </div>
    </div>
  );
}
