import React from "react";
import Header from "../components/header/Header";
import Greeting from "./greeting/Greeting";
import Skills from "./skills/Skills";
import WorkExperience from "./workExperience/WorkExperience";
import Education from "./education/Education";
import ScrollToTopButton from "./topbutton/Top";
import Contact from "./contact/Contact";
import "./Main.scss";

const Main = () => {
  return (
    <div className={"main"}>
      <Header />
      <Greeting />
      <Skills />
      <WorkExperience />
      <Education />
      <Contact />
      <ScrollToTopButton />
    </div>
  );
};

export default Main;
