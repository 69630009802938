import React from "react";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "./index.css";
import App from "./App";
import lazySizes from "lazysizes";
import {createRoot} from "react-dom/client";
import {reportWebVitals} from "./utils";

lazySizes.cfg.lazyClass = "lazy";
lazySizes.cfg.loadedClass = "lazy-loaded";
const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  reportWebVitals();
}
