export const base64Pixel =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

export const scrollTo = (anchor, offsetAllowed = true) => {
  const element = document.getElementById(anchor);
  if (element) {
    let offset = 0;

    if (offsetAllowed) {
      offset = 100; // Ajoute un décalage de 10px
    }
    const topPosition =
      element.getBoundingClientRect().top + window.scrollY - offset;

    window.scrollTo({
      top: topPosition,
      behavior: "smooth"
    });
  }
};

export const reportWebVitals = () => {
  import("web-vitals").then(({onCLS, onFCP, onFID, onLCP, onTTFB}) => {
    const logMetric = metric => {
      console.log(`Web Vital - ${metric.name}`, {
        value: metric.value,
        delta: metric.delta,
        id: metric.id
      });
    };
    onCLS(logMetric);
    onFID(logMetric);
    onLCP(logMetric);
    onTTFB(logMetric);
    onFCP(logMetric);
  });
};
