import React from "react";
import "./Contact.scss";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import {contactInfo} from "../../portfolio";

export default function Contact() {
  return (
    <div className="main-contact" id="contact">
      <h2 className="heading contact-title">{contactInfo.title}</h2>
      <div className={"contact-text-div"}>
        <a
          className="contact-detail-email"
          href={"mailto:" + contactInfo.email_address}
        >
          {contactInfo.email_address}
        </a>
        <br />
        <br />
        <SocialMedia />
      </div>
    </div>
  );
}
