import React from "react";
import "./WorkExperience.scss";
import ExperienceCard from "../../components/experienceCard/ExperienceCard";
import {workExperiences} from "../../portfolio";

export default function WorkExperience() {
  return (
    <div id="experience">
      <div className="experience-container" id="workExperience">
        <h2 className="experience-heading">Expériences Professionnelles</h2>
        <div className="experience-cards-div">
          {workExperiences.experience.map((card, i) => {
            return <ExperienceCard key={i} cardInfo={card} />;
          })}
        </div>
      </div>
    </div>
  );
}
